<template>
  <div class="card_warp">
    <div style="height:calc(100vh - 80px);overflow:auto">
      <TopCard text="答题卡" />
      <p>
        答题情况：
      </p>
      <div class="cards">
        <div class="card"
             @click="checkTopic(item,index)"
             v-for="(item,index) in copyTopicList"
             :key="index">
          <span v-if="getValue(item,index) != 'text'"
                :class="getValue(item,index) == index +1 ?'':'active'">{{getValue(item,index)}}</span>
          <div v-else
               :style="{'font-size':'14px','color':images.length?'#2395F5':'','background':images.length?'#EFF3FF':''}">
            <div>纸上作答</div>
            <div>上传图片</div>
          </div>
        </div>
      </div>
      <p>请添加图片：<span style="color:#BEBEBE">(最多可添加6张图片)</span> </p>
      <div class="img_warp">
        <div class="image"
             v-for="(item,index) in images"
             :key="item">
          <a-popconfirm title="确定删除这个图片?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="delteImage(item,index)">
            <img class="close"
                 src="@/static/del.png"
                 alt="">
          </a-popconfirm>
          <a-image :src="addHeader(item)"
                   width="120rem"
                   height="120rem"
                   :key="item">
          </a-image>
        </div>
        <div class="add_img"
             v-if="images.length  != 6"
             @click="uploadImage">
          <div>
            <img src="@/static/add_img.png"
                 alt="">
          </div>
          点击上传
        </div>
      </div>
    </div>
    <SubmitDialog ref="submitDialogRef"
                  @sava="sava" />
    <div class="btn_warp"
         @click="submitAll">
      提交
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import SubmitDialog from './components/submitPaper.vue'
import { checkArr as globalCheckArr } from '@/init/index.js'
import { submitPaper } from '@/api/paper.js'
import { message } from 'ant-design-vue'
import { uploadImageAuto } from '@/api/upload.js'
import { addHeader as globalAddHeader } from '@/init/index.js'
import TopCard from '@/views/components/top_card/index.vue'

let router = useRouter()
let route = useRoute()

let info = reactive({})
let copyTopicList = ref([])
let images = ref([])
let checkArr = globalCheckArr
const addHeader = globalAddHeader
const submitDialogRef = ref(null)


onMounted(() => {
  let paper = JSON.parse(window.sessionStorage.getItem('paper'))
  for (const key in paper) {
    info[key] = paper[key]
  }
  if (window.sessionStorage.getItem('images')) {
    images.value = JSON.parse(window.sessionStorage.getItem('images'))
  }
  copyTopic(info.questions)
})

const copyTopic = (arr) => {
  for (var index = 0; index < arr.length; index++) {
    if (arr[index].question_children) {
      copyTopicList.value.push(...arr[index].question_children)
    } else {
      copyTopicList.value.push(arr[index])
    }
  }
}

const getValue = (item, index) => {
  if (checkArr.indexOf(item.topic) != -1) {
    if (item.answer.length) {
      return item.answer.join(',')
    } else {
      return index + 1
    }
  } else {
    return 'text'
  }
}
// 点击上传图片
const uploadImage = async () => {
  const { data } = await uploadImageAuto(20, "image/*")
  images.value.push(data.dir)
}
// 点击提交全部
const submitAll = () => {
  let number = 0
  copyTopicList.value.forEach(item => {
    if (item.answer.length == 0 && checkArr.indexOf(item.topic) != -1) {
      number += 1
    } else if (images.value.length == 0 && checkArr.indexOf(item.topic) == -1) {
      number += 1
    }
  })
  submitDialogRef.value.setData(number, true)
}

const sava = async () => {
  let params = {
    user_paper_id: info.user_paper_id,
    other_answer: JSON.stringify(images.value),
    chs_answer: getAnswer(copyTopicList.value)
  }
  await submitPaper(params)
  message.success("提交成功")
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('paper')
  router.go(-2)
}

const getAnswer = (topicList) => {
  var arr = {}
  topicList.forEach(item => {
    if (item.answer.length != 0) {
      let answerStr = item.answer instanceof Array ? item.answer.join(',') : item.answer
      arr[item.answer_id] = answerStr
    }
  })
  return arr
}



const checkTopic = (item, index) => {
  window.sessionStorage.setItem('testpaperCardIndex', `${index + 1}`)
  window.sessionStorage.setItem('images', JSON.stringify(images.value || []))
  router.go(-1)
}

const delteImage = (item, index) => {
  console.log(index, "让用户确认删除这个图片")
  images.value.splice(index, 1)
}
</script>

<style lang="scss" scoped>
p {
  font-size: 20rem;
}
.card_warp {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
  p {
    margin-top: 15px;
    text-indent: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .cards {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 20rem;
    .card {
      width: 120rem;
      height: 120rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666;
      font-weight: bold;
      font-size: 32rem;
      margin-right: 24rem;
      margin-bottom: 30rem;
      background: #f6f6f6;
      border-radius: 10rem;
      cursor: pointer;
    }
  }
  .img_warp {
    display: flex;
    margin-left: 20px;
    margin-top: 19px;
    .image {
      position: relative;
      width: 120rem;
      height: 120rem;
      margin-right: 24rem;
      justify-content: center;
      .close {
        position: absolute;
        right: -5px;
        top: -5px;
        background: red;
        color: white;
        width: 30rem;
        height: 30rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8px;
        z-index: 19;
      }
    }
    .add_img {
      width: 120rem;
      height: 120rem;
      font-size: 20rem;
      font-weight: 500;
      background: #f6f6f6;
      border-radius: 10rem;
      text-align: center;
      color: #2196f3;
      cursor: pointer;
      img {
        width: 40rem;
        height: 34rem;
        margin-top: 26rem;
        margin-bottom: 14rem;
      }
    }
  }
  .btn_warp {
    display: flex;
    width: 1840rem;
    height: 60rem;
    justify-content: center;
    align-items: center;
    // position: absolute;

    background: #2196f3;
    border-radius: 10px;
    bottom: 20px;
    font-size: 24rem;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
  }
}

.active {
  background-color: #ecf5fc !important;
  color: #2196f3;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>